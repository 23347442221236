import React from 'react'
import tw, { css } from 'twin.macro'

import Img from 'static/images/about/our-curriculum.jpg'

export default () => {
  return (
    <section className="container mx-auto mt-16 px-4 flex flex-col md:flex-row md:justify-between">
      <div className="order-1 max-w-2xl">
        <h2 className="text-center md:text-left text-2xl md:text-4xl mb-2 md:mb-10">
          <span className="font-light">OUR</span> CURRICULUM
        </h2>

        <div
          css={[
            css`
              p {
                ${tw`mb-4`}
              }
            `,
          ]}
        >
          <p>
            We offer a comprehensive curriculum for Early Childhood focusing on
            all content areas in the learning of the English language.
          </p>

          <p>
            The curriculum focuses on{' '}
            <span className="font-bold">
              speaking, listening, reading and writing
            </span>
            across all curricular areas.
          </p>

          <p>
            Individualized curriculum packages are created at each level, which
            come with complete modules, lesson plans, assessments, and teacher
            training.
          </p>
        </div>
      </div>

      <img
        src={Img}
        alt=""
        className="order-0 md:order-2 w-4/5 md:w-2/5 mx-auto md:mx-0 mb-8 my-auto md:mb-auto md:ml-6"
      />
    </section>
  )
}
