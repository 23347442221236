import React from 'react'

export default () => {
  return (
    <section className="text-center px-2 py-6 md:py-16 bg-primary-200">
      <div className="container mx-auto">
        <h2 className="mb-4">Our Mission</h2>
        <p className="mb-4">
          Little Scholar is an organization dedicated to delivering a student
          focused, highly contextualized American English learning program that
          accommodates all levels of proficiency to master the English language.
        </p>
        <p>
          Little Scholar offers a comprehensive curriculum for Early Childhood
          focusing on all content areas in the learning of the English language.
          The curriculum packages are created at each level with modules, lesson
          plans, assessments, and teacher training that focuses on speaking,
          listening, reading and writing across all curricular areas.
        </p>
      </div>
    </section>
  )
}
