import React from 'react'
import tw, { css } from 'twin.macro'

import LanguageImg from 'static/images/about/language.jpg'
import CultureImg from 'static/images/about/culture.jpg'
import TeacherImg from 'static/images/about/teacher.jpg'

export default () => {
  const content = data

  return (
    <section className="container mx-auto px-2 py-6 md:py-10 text-center">
      <h2 className="mb-4">Creating Value in Education</h2>

      <div className="md:grid md:grid-flow-col px-2 md:px-0">
        {content.map(({ src, title, description }) => (
          <div className="mb-4 md:mb-0 px-2 h-full" key={title}>
            <div className="mx-auto w-full h-full max-w-xs lg:max-w-sm lg:mx-auto mb-2 flex flex-col items-center md:border">
              <div
                css={[
                  tw`w-full h-64 flex flex-col items-center justify-end border`,
                  css`
                    background-image: url(${src});
                    background-size: cover;
                    background-position: center;
                  `,
                ]}
              >
                <div className="w-full py-2 bg-primary-800 bg-opacity-75">
                  <h3 className="text-gray-200 text-xl">{title}</h3>
                </div>
              </div>

              <p className="p-2">{description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

const data = [
  {
    src: LanguageImg,
    title: 'Master the English Language',
    description:
      'Little Scholar students will gain confidence as they utilize their English language in natural learning opportunities.  Little Scholar students will be able to apply their knowledge across many disciplines.',
  },
  {
    src: CultureImg,
    title: 'Immerse in American Culture',
    description:
      'Our curriculum focuses on fostering confidence, along with logical thinking and reasoning skills. Students will be immersed in the American culture which is intertwined effectively within the motivating learning activities.',
  },
  {
    src: TeacherImg,
    title: 'Designed by Veteran Teachers',
    description:
      'To ensure academic excellence, veteran teachers with over 40 years experience have incorporated the state standards to align all of the learning.  Our curriculum, professional training and academic materials are all aligned with these standards in mind.',
  },
]
