import React from 'react'

import Layout from 'components/layouts/layout'
import SEO from 'components/seo'
import Mission from 'components/pages/about/mission'
import CreatingValue from 'components/pages/about/creating-value'
import EmbracingTheFuture from 'components/pages/about/embracing-the-future'
import OurCurriculum from 'components/pages/about/our-curriculum'
import OurStaff from 'components/pages/about/our-staff'

/**
 * About Page
 */
export default ({ location }) => (
  <Layout title="About Us" fullWidth={true}>
    <SEO title="About Little Scholar" path={location.pathname} />

    <Mission />
    <CreatingValue />
    <EmbracingTheFuture />
    <OurCurriculum />
    <OurStaff />

    <div className="container mx-auto border-b border-gray-400"></div>
  </Layout>
)
