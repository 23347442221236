import React from 'react'
import tw, { css } from 'twin.macro'

import GirlImg from 'static/images/about/girl-in-pink.jpg'

export default () => {
  const content = data

  return (
    <section className="container mx-auto px-4 mt-6">
      <div className="flex flex-row w-full">
        <img
          css={[
            tw`hidden md:block w-1/3 h-auto my-auto mr-10`,
            css`
              max-height: 500px;
            `,
          ]}
          src={GirlImg}
          alt=""
        />

        <div>
          <h2 className="text-center md:text-left text-2xl md:text-4xl mb-2 md:mb-10">
            EMBRACING <span className="font-light">THE FUTURE</span>
          </h2>

          <ul>
            {content.map(({ header, items }) => (
              <li key={header}>
                <h3 className="text-lg mb-2 italic">{header}</h3>

                <ul
                  key={header}
                  css={[
                    tw`ml-4`,
                    css`
                      li::before {
                        content: '•';
                        margin: 0 0.3rem 0 -0.25rem;
                        position: relative;
                        top: 0.08rem;
                        ${tw`text-blue-300`}
                      }
                    `,
                  ]}
                >
                  {items.map((text, index) => (
                    <li key={index} className="mb-2">
                      {text}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

const data = [
  {
    header: 'Holistic play-based and experiential learning',
    items: [
      'Our curriculum helps children develop and master the English language in an engaging and enjoyable way.',
      'Students emerge in their ability to apply and speech confidently in natural opportunities.',
      'Students confidently practice what they have been taught in every aspect of their learning environment.',
    ],
  },
  {
    header:
      'Nurturing confidence, logical thinking and social skills through developmentally appropriate practices',
    items: [
      'Curriculum designed by veteran American teachers.',
      'Based on state standards.',
      'Immersed in American culture through classes in English language, speech, science and technology, social and emotional, health and physical development.',
    ],
  },
]
