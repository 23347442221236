import React from 'react'

import ContainedButton from 'components/buttons/contained-button'

export default () => {
  const team = [
    'Highly trained professionals',
    'Strong academic team of curriculum specialist',
    'Academic administrators with over 40 years experience',
  ]

  return (
    <section className="container mx-auto px-4 my-12 md:my-20">
      <div className="mx-auto max-w-4xl md:flex flex-row rounded-2xl border-4 border-blue-900">
        <div className="md:w-3/5 py-6 px-6 rounded-b-2xl md:rounded-b-none md:rounded-r-2xl bg-blue-900 text-gray-100">
          <h2 className="text-gray-100 text-center mb-4 text-3xl">OUR STAFF</h2>

          <p className="mb-4">
            Our staff is comprised of dedicated veteran educators committed to
            providing an enriching and well-rounded curriculum for children to
            master the English language
          </p>

          <p className="mb-2">Our diversified team comprises:</p>

          <ul className="list-disc list-inside ml-2">
            {team.map((t, index) => (
              <li key={index}>{t}</li>
            ))}
          </ul>
        </div>

        <div className="flex-1 py-6 px-6 flex flex-col justify-between items-center">
          <h2 className="text-center mb-4 leading-9 text-3xl">
            INTERESTED IN <nobr>BECOMING A</nobr> <br />
            <span className="text-blue-700 text-2xl font-sans font-normal">
              Little Scholar Franchise?
            </span>
          </h2>

          <div className="flex-1 flex items-center justify-center">
            <ContainedButton>Contact us to find out how!</ContainedButton>
          </div>
        </div>
      </div>
    </section>
  )
}
